<template>
  <div id="patrolTaskManagement">
    <CSTabBar
      :tabbar="tabBarList"
      :checked-tab="tabBarType"
      @changeTabBar="changeTabBar"
    ></CSTabBar>
    <div class="result-panel">
      <CSTable :thead-top="filterHeight" v-if="tabBarType == 0">
        <template v-slot:thead>
          <tr>
            <th>添加时间</th>
            <th>任务名称</th>
            <th>机器人</th>
            <th>巡视点</th>
            <th>执行时间</th>
            <th>速度（m/s）</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="item in patrolTaskList" :key="item.integer">
            <td style="min-width:130px;">{{ item.createTime }}</td>
            <td>{{ item.patrolTaskName }}</td>
            <td>{{ item.robotName }}</td>
            <td>
              <span class="allow-click" @click="lookPatrolPointDetails(item)">{{
                item.patrolPointNum
              }}</span>
            </td>
            <td>
              {{ item.isAuto == 1 ? "不定时": item.taskTime }}
            </td>
            <td>
              {{ item.patrolSpeed }}
            </td>
            <td>
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-primary dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  操作
                </button>
                <ul class="dropdown-menu" style="min-width: 76px; width: 100px">
                  <li>
                    <a style="width: 100%" @click="turnOnAutomaticPatrol(item)"
                      >启动任务</a
                    >
                  </li>
                  <li>
                    <a style="width: 100%" @click="navigatorUpdate(item)"
                      >修改任务</a
                    >
                  </li>
                  <li>
                    <a
                      style="width: 100%"
                      @click="showDeletePatrolTaskDialogs(item.id)"
                      >删除任务</a
                    >
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </template>
      </CSTable>
      <CSTable :thead-top="filterHeight" v-else>
        <template v-slot:thead>
          <tr>
            <th>更新时间</th>
            <th>地图ID</th>
            <th>地图名称</th>
            <th>机器人</th>
            <th>详情</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="item in robotMapList" :key="item.id">
            <td style="min-width:130px;">{{ item.createTime }}</td>
            <td>{{ item.id }}</td>
            <td>{{ item.mapName }}</td>
            <td>{{ item.robotDeviceName }}</td>
            <td>
              <span class="allow-click" @click="lookMapDetails(item.mapUrl)"
                >查看</span
              >
            </td>
            <td>
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-primary dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  操作
                </button>
                <ul class="dropdown-menu" style="min-width: 76px; width: 100px">
                  <li>
                    <a style="width: 100%" @click="navigatorToAdd(item)" v-if ='item.isCreate == 1'
                      >创建任务</a
                    >
                    <a
                      style="width: 100%; color: #cccccc"
                      class="deleteBNtn"
                      v-else
                    >
                      创建任务
                    </a>
                  </li>
                  <li>
                    <a
                      v-if="item.isDelete != 1"
                      style="width: 100%"
                      @click="showDeletePatrolTaskDialog(item.id)"
                      >删除</a
                    >
                    <a
                      style="width: 100%; color: #cccccc"
                      class="deleteBNtn"
                      v-else
                    >
                      删除
                    </a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </template>
      </CSTable>
      <!-- //分页 -->
      <Pagination name="pagination" componentName="Pagination"></Pagination>
    </div>
    <CSDialog
      dialog-header-class=" "
      dialog-width="560px"
      :dialog-visible="deletePatrolTaskVisibles"
      dialog-header-icon="icon-menua-zu13"
      dialog-confirm-btn-text="确定"
      @onConfirm="deletePro()"
      @onClose="closePartBtn"
    >
      <template v-slot:dialog-content>
        <div style="padding: 30px; font-size: 24px; text-align: center">
          确定删除吗？
        </div>
      </template>
    </CSDialog>
    <CSDialog
      dialog-title="查看巡视点详情"
      dialog-width="920px"
      :dialog-visible="patrolPointDetailsVisible"
      :dialog-show-confirm-btn="false"
      dialog-cancel-btn-text="关闭"
      @onClose="patrolPointDetailsVisible = false"
      dialogHeaderClass="alert-bg"
    >
      <template v-slot:dialog-content>
        <div style="padding: 0 30px 30px 30px">
          <patrolMap :item="items"></patrolMap>
        </div>
      </template>
    </CSDialog>
    <CSDialog
      dialog-header-class=" "
      dialog-width="560px"
      :dialog-visible="turnOnAutomaticPatrolVisible"
      dialog-header-icon="icon-menua-zu13"
      @onClose="turnOnAutomaticPatrolVisible"
      @onConfirm="startUpRote"
      dialog-confirm-btn-text="确定"
    >
      <template v-slot:dialog-content>
        <div style="padding: 30px; font-size: 24px; text-align: center">
          确定立即开启自动巡视吗?
        </div>
      </template>
    </CSDialog>
    <CSDialog
      dialog-header-class=" "
      dialog-width="560px"
      :dialog-visible="deletePatrolTaskVisible"
      dialog-header-icon="icon-menua-zu13"
      dialog-confirm-btn-text="确定"
      @onConfirm="deleteRobotMap()"
      @onClose="closePartBtn"
    >
      <template v-slot:dialog-content>
        <div style="padding: 30px; font-size: 24px; text-align: center">
          确定删除吗？
        </div>
      </template>
    </CSDialog>

    <CSDialog
      dialog-title="查看地图详情"
      dialog-width="920px"
      :dialog-visible="mapDetailsVisible"
      @onClose="closeMapDetailsVisible"
      dialogHeaderClass="alert-bg"
      dialogCancelBtnText="关闭"
      :dialogShowConfirmBtn='false'
    >
      <template v-slot:dialog-content>
        <div
          style="
            padding: 30px;
            margin-top: 0 auto;
            align-items: center;
            text-align: center;
          "
          class="block"
        >
          <el-image :src="mapUrl" fit="contain">
            <div slot="placeholder"><i class="el-icon-loading"></i>加载中</div>
            <div slot="error">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
      </template>
    </CSDialog>
  </div>
</template>

<script>
import CSTabBar from "@/components/common/CSTabBar";
import CSTable from "@/components/common/CSTable";
import Pagination from "@/components/Pagination";
import CSDialog from "@/components/common/CSDialog";
import patrolMap from "./PatrolMap.vue";
import {
  robotDeviceUrl,
  queryAllPatrolTaskUrl,
  deleteRoboDeviceByIdUrl,
  queryAllRobotPatrolMapUrl,
  deleteRobotPatrolMapUrl,
  queryPatrolTaskByIdUrl,
  deletePatrolTaskUrl,
} from "@/requestUrl";
import { ROBOT_STATE } from "@/constant";
export default {
  name: "patrolTaskManagement",
  components: {
    CSTabBar,
    CSTable,
    Pagination,
    CSDialog,
    patrolMap,
  },
  data() {
    return {
      ROBOT_STATE,
      taskId: "", //任务ID
      items: "", //单个列表信息
      filterHeight: "",
      tabBarList: {
        0: "巡视任务管理",
        1: "地图列表",
      },
      tabBarType: 0,
      patrolPointDetailsVisible: false,
      mapDetailsVisible: false,
      turnOnAutomaticPatrolVisible: false,
      deletePatrolTaskVisible: false,
      deletePatrolTaskVisibles: false,
      patrolTaskId: 0,
      patrolTaskIds: 0,
      robotId: 0,
      regionCode: this.$vc.getCurrentRegion().code,
      patrolTaskList: [],
      robotMapList: [],
      mapUrl: "",
    };
  },
  mounted() {
    this.queryPatrolTaskList();
    this.filterHeight = document.querySelector(".filter-panel")?.offsetHeight;
    window.addEventListener("resize", () => {
      this.filterHeight = document.querySelector(".filter-panel")?.offsetHeight;
    });
  },
  methods: {
    //启动机器人
    startUpRote(item) {
      this.$fly.get(`${robotDeviceUrl}${this.taskId}`).then((res) => {
        if (res.code != 0) {
          return;
        }
        this.turnOnAutomaticPatrolVisible = false;
        this.$vc.toast("启动成功");
        this.queryPatrolTaskList();
      });
    },
    closePartBtn() {
      this.deletePatrolTaskVisible = false;
      this.deletePatrolTaskVisibles = false;
    },
    //删除巡检任务
    deletePro() {
      var id = this.patrolTaskIds;
      console.log(id);
      this.$fly.delete(deletePatrolTaskUrl + "/" + id).then((res) => {
        if (res.code != 0) {
          return;
        }
        this.$vc.toast("删除成功");
        this.deletePatrolTaskVisible = false;
        this.deletePatrolTaskVisibles = false;
        this.queryPatrolTaskList();
      });
    },
    //查询单个巡检任务
    async getTask(item) {
      const taskL = await this.$fly.get(queryPatrolTaskByIdUrl + "/" + item);
      if (taskL.code != 0) {
        return;
      }
      return taskL.data;
    },
    changeTabBar(type = 0) {
      this.tabBarType = type;
      if (type == 0) {
        console.log(type);
      } else {
        this.queryRobotMapList();
      }
    },
    async lookPatrolPointDetails(item) {
      this.items = await this.getTask(item.id);
      this.patrolPointDetailsVisible = true;
    },
    lookMapDetails(mapUrl) {
      this.mapUrl = mapUrl;
      this.mapDetailsVisible = true;
    },
    closeMapDetailsVisible() {
      this.mapUrl = "";
      this.mapDetailsVisible = false;
    },
    turnOnAutomaticPatrol(item) {
      this.taskId = item.id;
      this.turnOnAutomaticPatrolVisible = true;
    },
    showDeletePatrolTaskDialog(id) {
      this.patrolTaskId = id;
      this.deletePatrolTaskVisible = true;
    },
    showDeletePatrolTaskDialogs(id) {
      console.log("删除任务");
      this.deletePatrolTaskVisibles = true;
      this.patrolTaskIds = id;
    },
    // 查询巡视任务列表
    async queryPatrolTaskList() {
      const res = await this.$fly.get(
        queryAllPatrolTaskUrl + "/" + this.$vc.getCurrentRegion().code
      );
      if (res.code != 0) {
        return;
      }
      this.patrolTaskList = res.data;
      console.log(res.data);
    },
    // // 删除巡视任务
    // async deletePatrolTask() {
    //   const res = await this.$fly.delete(
    //     `${deleteRoboDeviceByIdUrl}/${this.patrolTaskId}`
    //   );
    //   if (res.code != 0) {
    //     return;
    //   }
    //   this.deletePatrolTaskVisible = false;
    //   this.$vc.toast("删除成功");
    // },
    // 查询机器人地图列表
    async queryRobotMapList() {
      const res = await this.$fly.post(
        queryAllRobotPatrolMapUrl,{
          regionCode:this.regionCode,
        }
      );
      if (res.code != 0) {
        return;
      }
      this.robotMapList = res.data.datas;
      console.log(this.robotMapList);
    },
    // 删除机器人地图
    async deleteRobotMap() {
      console.log(this.patrolTaskId);
      const res = await this.$fly.delete(
        `${deleteRobotPatrolMapUrl}/${this.patrolTaskId}`
      );
      if (res.code !== 0) {
        return;
      }
      this.$vc.toast("删除成功");
      this.deletePatrolTaskVisible = false;
      this.deletePatrolTaskVisibles = false;
      this.queryRobotMapList();
    },
    // 跳转到添加修改任务
    navigatorToAdd(item) {
      this.$router.push({ path: "patrolTaskManagement/add", query: { item } });
    },
    //跳转到修改任务
    async navigatorUpdate(item) {
      var items = await this.getTask(item.id);
      console.log(items);
      this.$router.push({
        path: "patrolTaskManagement/update",
        query: { item: items },
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.deleteBNtn {
  cursor: not-allowed;
}

.deleteBNtn:hover {
  background-color: #cccccc !important;
}
</style>
